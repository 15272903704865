    // src/sass/pages/_about.scss

    @import '../abstracts/variables';

    .about {
            
            @media only screen and (max-width: 992px) {
                &__hero {
                    height: 90vh;
                    margin-top: -12rem !important;
                }
            }
            
            @media only screen and (max-width: 768px) {
                &__hero {
                    height: auto;
                    min-height: 85vh;
                    margin-top: -10rem !important;
                }
            }
            
            @media only screen and (max-width: 480px) {
                &__hero {
                    min-height: 75vh;
                    margin-top: -8rem !important;
                }
            }
            
            @media only screen and (max-width: 375px) {
                &__hero {
                    min-height: 70vh;
                    margin-top: -7rem !important;
                }
            }
            
            .row{
                max-width: unset;
                margin-bottom: unset;
            }
            
            &__hero {
                position: relative;
                width: 100%;
                height: 100vh;
                overflow: hidden;
                margin-top: -16rem !important;
                
                &__background {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
                
                &__image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    transition: transform 10s ease;
                    
                    &:hover {
                        transform: scale(1.1);
                    }
                }
                
                &__overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(135deg, 
                        rgba(0, 0, 0, 0.7) 0%, 
                        rgba(0, 0, 0, 0.5) 50%,
                        rgba(0, 0, 0, 0.6) 100%);
                    z-index: 2;
                    
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 180px;
                        background: linear-gradient(to bottom, 
                            rgba(0, 0, 0, 0.85) 0%, 
                            rgba(0, 0, 0, 0.5) 60%,
                            rgba(0, 0, 0, 0) 100%);
                        z-index: 1;
                    }
                }
                
                &__content {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    z-index: 3;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding-top: 9rem;
                    
                    @media only screen and (max-width: 992px) {
                        padding-top: 7rem;
                    }
                    
                    @media only screen and (max-width: 768px) {
                        padding-top: 5rem;
                    }
                    
                    @media only screen and (max-width: 480px) {
                        padding-top: 3rem;
                    }
                }
                
                &__text-container {
                    max-width: 80%;
                    padding: 0 2rem;
                    animation: fadeInUp 1s ease-out forwards;
                    text-align: center;
                    margin-top: 3rem;
                    
                    @media only screen and (max-width: 992px) {
                        max-width: 90%;
                        margin-top: 2rem;
                    }
            
            @media only screen and (max-width: 768px) {
                        max-width: 95%;
                        padding: 0 1.5rem;
                        margin-top: 1.5rem;
                    }
                    
                    @media only screen and (max-width: 480px) {
                  max-width: 100%;
                        padding: 0 1rem;
                        margin-top: 1rem;
                    }
                }
                
                &__heading {
                    font-size: 6rem;
                    font-weight: 800;
                    margin-bottom: 3rem;
                    line-height: 1.1;
                    text-align: center;
                    margin-top: 0;
                    letter-spacing: -1px;
                    color: #fff;
                    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
                    position: relative;
                    
                    @media only screen and (max-width: 992px) {
                        font-size: 4.5rem;
                        margin-bottom: 2.5rem;
                        line-height: 1.2;
                    }
                    
                    @media only screen and (max-width: 768px) {
                        font-size: 3.8rem;
                  margin-bottom: 2rem;
                        letter-spacing: -0.5px;
                    }
                    
                    @media only screen and (max-width: 480px) {
                        font-size: 2.8rem;
                        margin-bottom: 1.5rem;
                        line-height: 1.3;
                    }
                    
                    @media only screen and (max-width: 375px) {
                        font-size: 2.4rem;
                        margin-bottom: 1.2rem;
                    }
                    
                    .highlight {
                        color: #4CAF50;
                        position: relative;
                        display: inline-block;
                        text-shadow: 0 0 10px rgba(76, 175, 80, 0.4);
                        
                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 5px;
                            left: 0;
                    width: 100%;
                            height: 10px;
                            background-color: rgba(76, 175, 80, 0.2);
                            z-index: -1;
                            transform: skewX(-15deg);
                            
                            @media only screen and (max-width: 992px) {
                                height: 8px;
                                bottom: 3px;
                            }
                            
                            @media only screen and (max-width: 768px) {
                                height: 6px;
                                bottom: 2px;
                            }
                            
                            @media only screen and (max-width: 480px) {
                                height: 4px;
                                bottom: 1px;
                            }
                            
                            @media only screen and (max-width: 375px) {
                                height: 3px;
                                bottom: 0;
                            }
                        }
                    }
                }
                
                &__paragraph {
                    font-size: 2.2rem;
                    color: #e0e0e0;
                    margin-bottom: 4rem;
                    line-height: 1.6;
                    text-align: center;
                    margin-top: 0;
                    max-width: 100%;
                    position: relative;
                    
                    @media only screen and (max-width: 992px) {
                        font-size: 1.9rem;
                      margin-bottom: 3rem;
                        line-height: 1.5;
                    }
                    
                    @media only screen and (max-width: 768px) {
                        font-size: 1.7rem;
                        margin-bottom: 2.5rem;
                        line-height: 1.4;
                    }
                    
                    @media only screen and (max-width: 480px) {
                        font-size: 1.5rem;
                        margin-bottom: 2rem;
                        line-height: 1.4;
                    }
                    
                    @media only screen and (max-width: 375px) {
                        font-size: 1.3rem;
                        margin-bottom: 1.5rem;
                        line-height: 1.3;
                    }
                }
                
                &__button {
                    display: inline-block;
                    padding: 1.6rem 4.5rem;
                    font-size: 1.8rem;
                    color: #fff;
                    background: linear-gradient(45deg, #2E7D32 0%, #4CAF50 100%);
                    border: none;
                    border-radius: 50px;
                    text-decoration: none;
                    margin: 1.5rem auto;
                    transition: all 0.3s ease;
                    cursor: pointer;
                    box-shadow: 0 10px 25px rgba(76, 175, 80, 0.4);
                    position: relative;
                    overflow: hidden;
                    z-index: 1;
                    min-width: 300px;
                    text-align: center;
                    
                    @media only screen and (max-width: 992px) {
                        padding: 1.4rem 3.5rem;
                        font-size: 1.7rem;
                        min-width: 250px;
                        margin: 1.2rem auto;
                    }
                    
                    @media only screen and (max-width: 768px) {
                        padding: 1.2rem 3rem;
                        font-size: 1.6rem;
                        min-width: 220px;
                        margin: 1rem auto;
                    }
                    
                    @media only screen and (max-width: 480px) {
                        padding: 1rem 2.5rem;
                            font-size: 1.4rem;
                        min-width: 200px;
                        box-shadow: 0 8px 15px rgba(76, 175, 80, 0.3);
                    }
                    
                    @media only screen and (max-width: 375px) {
                        padding: 0.9rem 2rem;
                        font-size: 1.3rem;
                        min-width: 180px;
                        box-shadow: 0 5px 12px rgba(76, 175, 80, 0.3);
                    }
                    
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: -100%;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
                        transition: all 0.6s ease;
                        z-index: -1;
                    }
                    
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(45deg, #2E7D32 0%, #4CAF50 100%);
                        z-index: -2;
                        transition: all 0.4s ease;
                    }

                    &:hover {
                        transform: translateY(-3px) scale(1.02);
                        box-shadow: 0 15px 30px rgba(76, 175, 80, 0.5);
                        
                        &::before {
                            left: 100%;
                        }
                        
                        &::after {
                            filter: brightness(1.1);
                        }
                    }
                    
                    &:active {
                        transform: translateY(0) scale(0.98);
                        box-shadow: 0 5px 15px rgba(76, 175, 80, 0.4);
                    }

                    .arrow {
                        display: none;
                    }
                }
                
                &__stats {
                    display: flex;
                      justify-content: center;
                    margin-top: 5rem;
                    gap: 5rem;
                    flex-wrap: wrap;
                    
                    @media only screen and (max-width: 992px) {
                        margin-top: 4rem;
                        gap: 3rem;
                    }
                    
                    @media only screen and (max-width: 768px) {
                        margin-top: 3rem;
                        gap: 2rem;
                    }
                    
                    @media only screen and (max-width: 480px) {
                      margin-top: 2rem;
                        gap: 1.5rem;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                    }
                }
                
                .stat {
                    text-align: center;
                    background: rgba(76, 175, 80, 0.1);
                    padding: 2rem;
                    border-radius: 15px;
                    border-left: 3px solid #4CAF50;
                    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2), inset 0 0 20px rgba(76, 175, 80, 0.1);
                    transition: all 0.4s ease;
                    min-width: 150px;
                    backdrop-filter: blur(5px);
                    animation: fadeInRight 0.8s ease-out forwards;
                    animation-delay: calc(var(--index, 0) * 0.2s);
                    opacity: 0;
                    transform: translateX(20px);
                    
                    @media only screen and (max-width: 992px) {
                        padding: 1.5rem;
                        min-width: 140px;
                        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15), inset 0 0 15px rgba(76, 175, 80, 0.1);
                    }
                    
                    @media only screen and (max-width: 768px) {
                        padding: 1.2rem;
                        min-width: 130px;
                        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15), inset 0 0 10px rgba(76, 175, 80, 0.1);
                    }
                    
                    @media only screen and (max-width: 480px) {
                        padding: 1rem;
                        min-width: 120px;
                        width: 100%;
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15), inset 0 0 8px rgba(76, 175, 80, 0.1);
                    }
                    
                    @media only screen and (max-width: 375px) {
                        padding: 0.8rem;
                        min-width: 100px;
                        border-radius: 10px;
                        border-left-width: 2px;
                    }
                    
                    &:nth-child(1) {
                        --index: 1;
                    }
                    &:nth-child(2) {
                        --index: 2;
                    }
                    &:nth-child(3) {
                        --index: 3;
                    }
                    
                    &:hover {
                        transform: translateY(-8px);
                        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.25), inset 0 0 20px rgba(76, 175, 80, 0.2);
                        border-left: 3px solid #81C784;
                        background: rgba(76, 175, 80, 0.15);
                    }
                    
                    &__number {
                        font-size: 3.5rem;
                        font-weight: 800;
                        color: #fff;
                        margin: 0 0 0.8rem;
                        text-shadow: 0 0 10px rgba(76, 175, 80, 0.6);
                        
                        @media only screen and (max-width: 992px) {
                            font-size: 3rem;
                            margin: 0 0 0.6rem;
                        }
                        
                        @media only screen and (max-width: 768px) {
                            font-size: 2.6rem;
                            margin: 0 0 0.5rem;
                        }
                        
                        @media only screen and (max-width: 480px) {
                            font-size: 2.2rem;
                            margin: 0 0 0.4rem;
                        }
                        
                        @media only screen and (max-width: 375px) {
                            font-size: 2rem;
                            margin: 0 0 0.3rem;
                        }
                    }
                    
                    &__label {
                        font-size: 1.6rem;
                        font-weight: 500;
                        color: #A5D6A7;
                        margin: 0;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        
                        @media only screen and (max-width: 992px) {
                            font-size: 1.4rem;
                            letter-spacing: 0.3px;
                        }
                        
                        @media only screen and (max-width: 768px) {
                            font-size: 1.3rem;
                            letter-spacing: 0.2px;
                        }
                        
                        @media only screen and (max-width: 480px) {
                            font-size: 1.2rem;
                            letter-spacing: 0.1px;
                        }
                        
                        @media only screen and (max-width: 375px) {
                            font-size: 1.1rem;
                            letter-spacing: 0;
                        }
                    }
                }
            }
            
            &__info {
                display: flex;
                align-items: stretch;
                padding: 0;
                position: relative;
                background: #000;
                color: #fff;
                min-height: 100vh;
                overflow: hidden;
                margin-top: 12rem; /* Space for the fixed navbar */
            }
    
            &__content {
                flex: 1;
                width: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                z-index: 2;
                position: relative;
                background: linear-gradient(135deg, rgba(0,0,0,0.9) 0%, rgba(18, 50, 22, 0.9) 100%);
                overflow: hidden;
                
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='rgba(255,255,255,0.05)' fill-rule='evenodd'/%3E%3C/svg%3E");
                    opacity: 0.3;
                }
            }
    
            &__content-wrapper {
                max-width: 80%;
                padding: 0 2rem;
                animation: fadeInUp 1s ease-out forwards;
            }
    
            &__subheading {
                font-size: 6rem;
                font-weight: 800;
                margin-bottom: 3rem;
                line-height: 1.1;
                text-align: left;
                margin-top: 0;
                letter-spacing: -1px;
                text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
                position: relative;
                
                .highlight {
                    color: #4CAF50;
                    position: relative;
                    display: inline-block;
                    text-shadow: 0 0 10px rgba(76, 175, 80, 0.4);
                    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: 5px;
                        left: 0;
                        width: 100%;
                        height: 10px;
                        background-color: rgba(76, 175, 80, 0.2);
                        z-index: -1;
                        transform: skewX(-15deg);
                    }
                }
            }
    
            &__paragraph {
                font-size: 2.2rem;
                color: #e0e0e0;
                margin-bottom: 4rem;
                line-height: 1.6;
                text-align: left;
                margin-top: 0;
                max-width: 90%;
                position: relative;
            }
    
            &__button {
                display: inline-block;
                padding: 1.6rem 3.5rem;
                font-size: 1.8rem;
                color: #fff;
                background: linear-gradient(45deg, #2E7D32 0%, #4CAF50 100%);
                border: none;
                border-radius: 50px;
                text-decoration: none;
                margin: 1.5rem 0;
                transition: all 0.3s ease;
                cursor: pointer;
                box-shadow: 0 10px 25px rgba(76, 175, 80, 0.4);
                position: relative;
                overflow: hidden;
                z-index: 1;
                
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -100%;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
                    transition: all 0.6s ease;
                    z-index: -1;
                }
                
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(45deg, #2E7D32 0%, #4CAF50 100%);
                    z-index: -2;
                    transition: all 0.4s ease;
                }
    
                &:hover {
                    transform: translateY(-3px) scale(1.02);
                    box-shadow: 0 15px 30px rgba(76, 175, 80, 0.5);
                    
                    &::before {
                        left: 100%;
                    }
                    
                    &::after {
                        filter: brightness(1.1);
                    }
                }
                
                &:active {
                    transform: translateY(0) scale(0.98);
                    box-shadow: 0 5px 15px rgba(76, 175, 80, 0.4);
                }
    
                .arrow {
                    margin-left: 1rem;
                    font-size: 2.2rem;
                    vertical-align: middle;
                    transition: transform 0.3s ease;
                    display: inline-block;
                }
                
                &:hover .arrow {
                    transform: translateX(5px);
                }
            }
    
            &__stats {
                display: flex;
                justify-content: flex-start;
                margin-top: 5rem;
                gap: 2.5rem;
                flex-wrap: wrap;
            }
    
            .stat {
                text-align: center;
                background: rgba(76, 175, 80, 0.1);
                padding: 2rem;
                border-radius: 15px;
                border-left: 3px solid #4CAF50;
                box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2), inset 0 0 20px rgba(76, 175, 80, 0.1);
                transition: all 0.4s ease;
                min-width: 130px;
                backdrop-filter: blur(5px);
                animation: fadeInRight 0.8s ease-out forwards;
                animation-delay: calc(var(--index, 0) * 0.2s);
                opacity: 0;
                transform: translateX(20px);
                
                &:nth-child(1) {
                    --index: 1;
                }
                &:nth-child(2) {
                    --index: 2;
                }
                &:nth-child(3) {
                    --index: 3;
                }
                
                &:hover {
                    transform: translateY(-8px);
                    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.25), inset 0 0 20px rgba(76, 175, 80, 0.2);
                    border-left: 3px solid #81C784;
                    background: rgba(76, 175, 80, 0.15);
                }
                
                &__number {
                    font-size: 3.5rem;
                    font-weight: 800;
                    color: #fff;
                    margin: 0 0 0.8rem;
                    text-shadow: 0 0 10px rgba(76, 175, 80, 0.6);
                }
                
                &__label {
                    font-size: 1.6rem;
                    font-weight: 500;
                    color: #A5D6A7;
                    margin: 0;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }
            }
    
            &__display {
                flex: 1;
                width: 50%;
                position: relative;
                overflow: hidden;
                
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(135deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 50%);
                    z-index: 1;
                }
            }
    
            &__overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                z-index: 1;
            }
    
            &__display__image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                display: block;
                transition: all 8s ease;
                transform: scale(1.05);
                
                &:hover {
                    transform: scale(1);
                }
            }
    
            &__values {
                position: relative;
                padding: 8rem 4rem;
                background-color: #000000; // Black background
                color: #ffffff;
                
                &__container {
                    max-width: 1200px;
                    margin: 0 auto;
                }
                
                &__header {
                    text-align: center;
                    margin-bottom: 5rem;
                }
                
                &__heading {
                    font-size: 4.2rem;
                    font-weight: 700;
                    color: #ffffff;
                    margin-bottom: 1.5rem;
                    letter-spacing: 1px;
                }
                
                &__divider {
                    height: 4px;
                    width: 80px;
                    background: linear-gradient(90deg, rgba(0,150,136,0), #00c853, rgba(0,150,136,0));
                    margin: 0 auto;
                    border-radius: 2px;
                }
                
                &__grid {
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
                    gap: 4rem;
                    justify-content: center;
                }
                
                &__card {
                        position: relative;
                    background-color: rgba(255, 255, 255, 0.05);
                    border-radius: 15px;
                    padding: 3rem;
                    height: 400px;
                    transition: all 0.3s ease;
                    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
                    overflow: hidden;
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    
                    &:hover {
                        transform: translateY(-10px);
                        box-shadow: 0 25px 50px rgba(0, 0, 0, 0.3);
                        
                        .about__values__card-image img {
                            transform: scale(1.05);
                            opacity: 0.7;
                        }
                        
                        .about__values__card-content {
                            transform: translateY(-5px);
                        }
                        
                        .about__values__card-icon {
                            transform: translateY(0) scale(1.1);
                            opacity: 0.9;
                        }
                    }
                    
                    &-number {
                        font-size: 7rem;
                        font-weight: 700;
                        color: rgba(255, 255, 255, 0.1);
                        position: absolute;
                        top: 10px;
                        right: 20px;
                        line-height: 1;
                        z-index: 2;
                    }
                    
                    &-image {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        z-index: 0;
                        
                        &::after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(
                                to bottom,
                                rgba(0, 0, 0, 0.8) 0%,
                                rgba(0, 0, 0, 0.7) 30%,
                                rgba(0, 0, 0, 0.6) 60%,
                                rgba(0, 0, 0, 0.8) 100%
                            );
                            z-index: 1;
                        }
                        
                        img {
                        width: 100%;
                            height: 100%;
                        object-fit: cover;
                            opacity: 0.4;
                            transition: all 0.5s ease;
                            filter: grayscale(40%);
                        }
                    }
                    
                    &-content {
                        position: relative;
                        z-index: 2;
                        transition: transform 0.3s ease;
                    }
                    
                    &-title {
                        font-size: 2.4rem;
                        font-weight: 600;
                        color: #00c853;
                        margin-bottom: 1.5rem;
                        position: relative;
                        padding-bottom: 1.5rem;
                        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
                        
                        &::after {
                            content: '';
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 40px;
                            height: 3px;
                            background-color: #00c853;
                            border-radius: 3px;
                        }
                    }
                    
                    &-text {
                        font-size: 1.6rem;
                        line-height: 1.6;
                        color: rgba(255, 255, 255, 0.9);
                        text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
                    }
                    
                    &-icon {
                        position: absolute;
                        bottom: -30px;
                        right: -30px;
                        width: 100px;
                        height: 100px;
                    display: flex;
                        align-items: center;
                        justify-content: center;
                        color: rgba(0, 200, 83, 0.2);
                        font-size: 5rem;
                        transform: translateY(20px);
                        opacity: 0.5;
                        transition: all 0.4s ease;
                        z-index: 2;
                    }
                }
                
                @media only screen and (max-width: 768px) {
                    padding: 6rem 2rem;
                    
                    &__grid {
                        grid-template-columns: 1fr;
                        gap: 3rem;
                    }
                    
                    &__card {
                        padding: 2.5rem;
                        height: 350px;
                        
                        &-number {
                            font-size: 5rem;
                            top: 10px;
                            right: 15px;
                        }
                        
                        &-title {
                            font-size: 2.2rem;
                        }
                        
                        &-text {
                            font-size: 1.5rem;
                        }
                        
                        &-icon {
                            width: 80px;
                            height: 80px;
                            font-size: 4rem;
                        }
                    }
                }
            }
    
            &__reasons {
                padding: 5rem 0;
                background: linear-gradient(135deg, #f4f6f2 0%, #ffffff 50%, #f4f6f2 100%);
                color: black;
                position: relative;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 5px;
                    background: linear-gradient(90deg, rgba(230, 236, 225, 0.6), hsl(124, 47%, 43%), rgba(230, 236, 225, 0.6));
                }

                &__heading {
                    font-size: 5rem;
                    font-weight: 800;
                    text-align: center;
                    margin-bottom: 5rem;
                    color: hsl(124, 47%, 43%);
                    position: relative;
                    display: inline-block;
                    width: 100%;
                    text-shadow: 1px 1px 2px rgba(89, 135, 91, 0.1);
                    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -15px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 120px;
                        height: 3px;
                        background: linear-gradient(90deg, rgba(230, 236, 225, 0.6), hsl(124, 47%, 43%), rgba(230, 236, 225, 0.6));
                        border-radius: 3px;
                    }
                }

                .card {
                    background: #f7f9f5;
                    border-radius: 14px;
                    padding: 3.5rem 2.5rem 2.5rem;
                    box-shadow: 0 10px 30px rgba(89, 135, 91, 0.1);
                    text-align: center;
                    transition: all 0.3s ease;
                    border: 1px solid rgba(220, 230, 216, 0.7);
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    position: relative;
                    overflow: visible;
                    
                    &::before {
                        content: '';
                        position: absolute;
                        top: -30px;
                        left: -30px;
                        width: 90px;
                        height: 90px;
                        // background: #f7f9f5;
                        border-radius: 0 0 70px 0;
                        // box-shadow: 4px 4px 10px rgba(89, 135, 91, 0.1) inset;
                        z-index: 0;
                    }
                    
                    &::after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 30px;
                        // background: rgba(26, 10, 29, 0.05);
                        // background: hsl(129, 42%, 87%);;
                        z-index: 0;
                    }
                    
                    &:hover {
                        transform: translateY(-10px);
                        box-shadow: 0 15px 35px rgba(89, 135, 91, 0.2);
                        
                        .icon-circle {
                            background-color: hsl(124, 47%, 40%);
                            transform: scale(1.05);
                        }
                    }

                    .icon-circle {
                        position: absolute;
                        top: -25px;
                        left: -15px;
                        width: 65px;
                        height: 65px;
                        border-radius: 50%;
                        background-color: hsl(124, 47%, 43%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
                        transition: all 0.3s ease;
                        z-index: 2;
                        
                        svg {
                            color: white;
                            font-size: 2.4rem;
                        }
                    }

                    h3 {
                        font-size: 2.2rem;
                        margin-bottom: 1.5rem;
                        margin-top: 0.5rem;
                        color: #333;
                        text-align: left;
                        line-height: 1.4;
                        position: relative;
                        z-index: 1;
                    }

                    p {
                        font-size: 1.6rem;
                        color: #666;
                        line-height: 1.6;
                        text-align: left;
                        position: relative;
                        z-index: 1;
                    }
                }
                
                .row {
                    max-width: 1200px;
                    margin: 0 auto;
                    padding: 0 2rem;
                }
                
                .col-1-of-4 {
                    padding: 1.5rem;
                }
                
                @media only screen and (max-width: 768px) {
                    .card {
                        padding: 3.5rem 1.5rem 2rem;
                        margin-bottom: 3rem;
                        margin-left: 2px;
                        
                        
                        &::before {
                            top: -30px;
                            left: -30px;
                            width: 85px;
                            height: 85px;
                        }
                        
                        .icon-circle {
                            top: -25px;
                            left: -15px;
                            width: 55px;
                            height: 55px;
                            
                            svg {
                                font-size: 2rem;
                            }
                        }
                        
                        h3 {
                            font-size: 1.8rem;
                            margin-top: 0.5rem;
                            // text-align: justify;
                        }
                        
                        p {
                            font-size: 1.4rem;
                        }
                    }
                }
            }
        }
    
        .faq-container {
            background: linear-gradient(135deg, rgba(18, 18, 18, 0.02) 0%, rgba(30, 43, 30, 0.05) 50%, rgba(76, 175, 80, 0.03) 100%);
            padding: 5rem 2rem;
            margin: 2rem 0;
        }

        .faq-section {
            padding: 3rem 2rem;
            background-color: #f4f6f2;
            border-radius: 10px;
            max-width: 1150px;
            margin: 4rem auto;
            box-shadow: 0 6px 20px rgba(89, 135, 91, 0.15), 0 2px 5px rgba(230, 236, 225, 0.3), inset 0 0 20px rgba(76, 175, 80, 0.05);
            background-image: linear-gradient(135deg, rgba(230, 236, 225, 0.7) 0%, rgba(245, 248, 245, 0.9) 50%, rgba(230, 236, 225, 0.7) 100%);
        }
    
        .faq-heading {
            font-size: 3rem;
            font-weight: 700;
            text-align: center;
            margin-bottom: 3rem;
            color: #121212;
            position: relative;
            text-shadow: 1px 1px 2px rgba(89, 135, 91, 0.1);
            
            &::after {
                content: '';
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
                width: 100px;
                height: 3px;
                background: linear-gradient(90deg, rgba(230, 236, 225, 0.6), hsl(124, 47%, 43%), rgba(230, 236, 225, 0.6));
                box-shadow: 0 1px 2px rgba(89, 135, 91, 0.1);
            }
        }
    
        .faq-tabs {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 1rem;
            margin-bottom: 3rem;
            
            .faq-tab {
                padding: 1rem 2rem;
                background-color: #f2f6f1;
                border: 2px solid #dce6d8;
                border-radius: 30px;
                font-size: 1.6rem;
                font-weight: 600;
                color: #121212;
                cursor: pointer;
                transition: all 0.3s ease;
                outline: none;
                box-shadow: 0 2px 4px rgba(89, 135, 91, 0.05);
                position: relative;
                overflow: hidden;
                
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(135deg, rgba(230, 236, 225, 0.4) 0%, rgba(255, 255, 255, 0.1) 50%, rgba(230, 236, 225, 0.4) 100%);
                    pointer-events: none;
                }
                
                &:hover {
                    background-color: #e6ede3;
                    border-color: #c5d5bd;
                    transform: translateY(-2px);
                    box-shadow: 0 4px 8px rgba(89, 135, 91, 0.08);
                }
                
                &.active {
                    background: linear-gradient(135deg, hsl(124, 47%, 38%) 0%, hsl(124, 47%, 43%) 50%, hsl(124, 47%, 38%) 100%);
                    color: #fff;
                    border-color: rgba(76, 175, 80, 0.4);
                    box-shadow: 0 4px 10px rgba(89, 135, 91, 0.2), inset 0 1px 3px rgba(255, 255, 255, 0.2);
                }
            }
        }
    
        .faq-content {
            background-color: #f7f9f5;
            border-radius: 10px;
            padding: 2rem;
            box-shadow: 0 4px 15px rgba(89, 135, 91, 0.1), inset 0 0 30px rgba(230, 236, 225, 0.6);
            position: relative;
            
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(135deg, rgba(230, 236, 225, 0.4) 0%, rgba(76, 175, 80, 0.07) 50%, rgba(230, 236, 225, 0.4) 100%);
                border-radius: 10px;
                pointer-events: none;
            }
        }
    
        .faq-item {
            background-color: #f2f6f1;
            border: 1px solid #dce6d8;
            border-radius: 8px;
            margin-bottom: 1.5rem;
            overflow: hidden;
            transition: all 0.3s ease;
            position: relative;
            z-index: 1;

            &.open {
                border-color: rgba(76, 175, 80, 0.4);
                box-shadow: 0 4px 10px rgba(89, 135, 91, 0.2);
                
                .faq-question {
                    background-color: rgba(76, 175, 80, 0.12);
                    color: hsl(124, 47%, 30%);
                    font-weight: 700;
                }
            }
    
            .faq-question {
                padding: 1.5rem;
                cursor: pointer;
                font-size: 1.6rem;
                font-weight: 600;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #121212;
                background-color: #f2f6f1;
                transition: all 0.3s ease;
                
                &:hover {
                    background-color: rgba(230, 236, 225, 0.8);
                }
            }
    
            .faq-answer {
                padding: 0;
                max-height: 0;
                font-size: 1.5rem;
                color: #444;
                background-color: #f7f9f5;
                border-top: 0 solid #dce6d8;
                overflow: hidden;
                transition: all 0.3s ease;
                line-height: 1.6;
            }
            
            &.open .faq-answer {
                padding: 1.5rem;
                max-height: 500px;
                border-top: 1px solid #dce6d8;
                box-shadow: inset 0 2px 5px rgba(89, 135, 91, 0.08);
            }
    
            .faq-icon {
                font-size: 1.4rem;
                font-weight: bold;
                color: hsl(124, 47%, 43%);
                transition: transform 0.3s ease;
            }
            
            &.open .faq-icon {
                transform: rotate(-90deg);
            }
        }
        
        @media only screen and (max-width: 768px) {
            .faq-tabs {
                flex-direction: column;
                
                .faq-tab {
                    width: 100%;
                }
            }
            
            .faq-heading {
                font-size: 2.4rem;
            }
            
            .faq-item .faq-question {
                font-size: 1.4rem;
            }
        }
    
        .footer {
            background: linear-gradient(135deg, #121212 0%, #1e2b1e 40%, hsl(124, 47%, 43%) 100%);
            color: #fff;
            padding: 1.5rem 0 0.5rem;
            border-radius: 2px 2px 0 0;
            width: 100%;
            margin: 0 auto;
            box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.2);
            position: relative;
            overflow: hidden;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 3px;
                background: hsl(124, 47%, 43%);
            }

            .container {
                display: flex;
                justify-content: space-between;
                max-width: 900px;
                margin: 0 auto;
                padding: 0 2rem;
            }

            &__section {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 0;
                padding: 0;
                width: 45%;

                h3 {
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    margin-left: -1rem;
                    font-weight: 600;
                    position: relative;
                    display: inline-block;
                    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        width: 60px;
                        height: 2px;
                        background-color: hsl(124, 47%, 43%);
                    }
                }

                ul {
                    list-style: none;
                    padding: 0;

                    li {
                        margin-bottom: 0.8rem;
                        margin-left: 2rem;
                        transition: transform 0.3s ease;

                        &:hover {
                            transform: translateX(5px);
                        }

                        a {
                            color: #f5f5f5;
                            text-decoration: none;
                            font-size: 1.4rem;
                            display: flex;
                            align-items: center;
                            transition: color 0.3s ease;

                            svg {
                                margin-right: 1rem;
                                color: hsl(124, 47%, 43%);
                                font-size: 1.8rem;
                            }

                            &:hover {
                                color: hsl(124, 47%, 55%);
                                text-decoration: none;
                            }
                        }
                    }
                }

                p {
                    font-size: 1.6rem;
                    display: flex;
                    align-items: center;

                    svg {
                        margin-right: 0.5rem;
                    }
                }
            }

            &__social {
                display: flex;
                gap: 1.5rem;
                margin-top: 2rem;
                
                .social-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background: rgba(255, 255, 255, 0.1);
                    color: #fff;
                    font-size: 1.8rem;
                    transition: all 0.3s ease;
                    
                    &:hover {
                        background: hsl(124, 47%, 43%);
                        color: #fff;
                        transform: translateY(-3px);
                        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
                    }
                }
            }

            &__contact {
                display: flex;
                flex-direction: column;
                margin-left: 0;
                margin-top: 0;
                padding: 0;
                width: 45%;

                h3 {
                    font-size: 2rem;
                    margin-bottom: 1rem;
                    margin-left: 2rem;
                    font-weight: 600;
                    position: relative;
                    display: inline-block;
                    
                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -5px;
                        left: 0;
                        width: 60px;
                        height: 2px;
                        background-color: hsl(124, 47%, 43%);
                    }
                }

                p {
                    font-size: 1.4rem;
                    margin-bottom: 0.8rem;
                    display: flex;
                    align-items: center;
                    transition: transform 0.3s ease;

                    &:hover {
                        transform: translateX(5px);
                    }

                    svg {
                        margin-right: 1.5rem;
                        color: hsl(124, 47%, 43%);
                        font-size: 1.8rem;
                        min-width: 20px;
                    }
                }
            }

            &__bottom {
                text-align: center;
                margin-top: 1.5rem;
                padding: 0.8rem 0;
                border-top: 1px solid rgba(255, 255, 255, 0.1);
                background-color: rgba(0, 0, 0, 0.2);

                p {
                    font-size: 1.2rem;
                    color: #ccc;
                }
            }
        }
        
    
    @media only screen and (max-width: 768px) {
        .about__values {
            &__image-container {
                flex-direction: column;
                align-items: center;
            }              
            
            .image-gallery {
                width: 100%;
                max-width: 100%;
                margin-right: 0;
                padding: 0 15px;
                
                &__main {
                    margin-bottom: 10px;
                }
                
                &__flag {
                    height: 200px;
                }
                
                &__flag-video {
                    height: 200px;
                }
                
                &__flag-gif {
                    height: 200px;
                }
                
                &__secondary {
                    gap: 10px;
                }
                
                &__image {
                    height: 150px;
                }
            }
            
            &__content {
                margin-left: 0;
                padding: 20px;
                text-align: center;
                
                .about__values__item {
                    margin-bottom: 30px;
                }
                
                .about__values__main {
                    font-size: 3rem;
                    margin-bottom: 20px;
                }
                
                .about__values__number {
                    font-size: 2.5rem;
                    margin-bottom: 10px;
                }
                
                .about__values__title {
                    font-size: 1.8rem;
                    margin-bottom: 10px;
                }
                
                .about__values__text {
                    font-size: 1rem;
                    max-width: 90%;
                    margin: 0 auto;
                }
            }
        }
    }
    
    @keyframes flag-wave-advanced {
      0% {
        transform: perspective(1000px) rotateY(0deg) rotateX(0deg) skewX(0deg);
      }
      10% {
        transform: perspective(1000px) rotateY(5deg) rotateX(2deg) skewX(1deg);
      }
      20% {
        transform: perspective(1000px) rotateY(10deg) rotateX(5deg) skewX(2deg);
      }
      30% {
        transform: perspective(1000px) rotateY(5deg) rotateX(2deg) skewX(1deg);
      }
      40% {
        transform: perspective(1000px) rotateY(0deg) rotateX(0deg) skewX(0deg);
      }
      50% {
        transform: perspective(1000px) rotateY(-5deg) rotateX(-2deg) skewX(-1deg);
      }
      60% {
        transform: perspective(1000px) rotateY(-10deg) rotateX(-5deg) skewX(-2deg);
      }
      70% {
        transform: perspective(1000px) rotateY(-5deg) rotateX(-2deg) skewX(-1deg);
      }
      80% {
        transform: perspective(1000px) rotateY(0deg) rotateX(0deg) skewX(0deg);
      }
      90% {
        transform: perspective(1000px) rotateY(5deg) rotateX(2deg) skewX(1deg);
      }
      100% {
        transform: perspective(1000px) rotateY(0deg) rotateX(0deg) skewX(0deg);
      }
    }
    
    @keyframes flag-shine {
      0% {
        transform: translateX(-100%);
        opacity: 0.5;
      }
      50% {
        transform: translateX(0%);
        opacity: 0.8;
      }
      100% {
        transform: translateX(100%);
        opacity: 0.5;
      }
    }
    
    @keyframes fadeInUp {
        from {
            opacity: 0;
            transform: translateY(30px);
        }
        to {
            opacity: 1;
            transform: translateY(0);
        }
    }
    
    @keyframes fadeInRight {
        from {
            opacity: 0;
            transform: translateX(20px);
        }
        to {
            opacity: 1;
            transform: translateX(0);
        }
    }
    
    // iPhone and Samsung specific optimizations
    @media only screen and (max-width: 428px) { 
        .about__hero {
            min-height: 70vh;
            margin-top: -8rem;
            
            &__content {
                padding-top: 3rem; 
            }
            
            &__text-container {
                padding: 1rem 0.8rem;
                max-width: 100%;
            }
            
            &__heading {
                font-size: 2.6rem;
                margin-bottom: 1.2rem;
                line-height: 1.2;
                
                .highlight::after {
                    height: 4px;
                    bottom: 0;
                }
            }
            
            &__paragraph {
                font-size: 1.4rem;
                line-height: 1.3;
                margin-bottom: 1.5rem;
            }
            
            &__button {
                min-width: 200px;
                width: 85%;
                max-width: 250px;
                padding: 1rem 2rem;
                font-size: 1.4rem;
                margin: 1rem auto;
            }
            
            &__stats {
                margin-top: 2rem;
                gap: 1.5rem;
                display: grid;
                grid-template-columns: 1fr 1fr;
                justify-items: center;
                
                .stat {
                    width: 100%;
                    min-width: auto;
                    max-width: 150px;
                    padding: 1rem;
                    
                    &__number {
                        font-size: 2.2rem;
                        margin-bottom: 0.3rem;
                    }
                    
                    &__label {
                        font-size: 1.1rem;
                        letter-spacing: 0;
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: 390px) {
        .about__hero {
            min-height: 65vh;
            
            &__heading {
                font-size: 2.4rem;
                margin-bottom: 1rem;
            }
            
            &__paragraph {
                font-size: 1.3rem;
                margin-bottom: 1.3rem;
            }
            
            &__button {
                min-width: 180px;
                width: 90%;
                padding: 0.9rem 1.8rem;
                font-size: 1.3rem;
            }
            
            &__stats {
                gap: 1.2rem;
                
                .stat {
                    max-width: 130px;
                    padding: 0.8rem;
                    
                    &__number {
                        font-size: 2rem;
                        margin-bottom: 0.2rem;
                    }
                }
            }
        }
    }
    
    @media only screen and (max-width: 360px) {
        .about__hero {
            min-height: 60vh;
            margin-top: -7rem;
            
            &__content {
                padding-top: 2.5rem;
            }
            
            &__text-container {
                padding: 0.8rem 0.5rem;
            }
            
            &__heading {
                font-size: 2.2rem;
                margin-bottom: 0.8rem;
                
                .highlight::after {
                    height: 3px;
                }
            }
            
            &__paragraph {
                font-size: 1.2rem;
                line-height: 1.25;
                margin-bottom: 1rem;
            }
            
            &__button {
                min-width: 160px;
                width: 90%;
                padding: 0.8rem 1.5rem;
                font-size: 1.2rem;
                margin: 0.8rem auto;
            }
            
            &__stats {
                margin-top: 1.5rem;
                gap: 1rem;
                
                .stat {
                    max-width: 120px;
                    padding: 0.7rem;
                    
                    &__number {
                        font-size: 1.8rem;
                        margin-bottom: 0.2rem;
                    }
                    
                    &__label {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
    
    // Fix for iPhone X notch (ensure content doesn't get cut off)
    @supports (padding-top: env(safe-area-inset-top)) {
        .about__hero {
            @media only screen and (max-width: 428px) {
                padding-top: calc(env(safe-area-inset-top) + 3rem);
            }
        }
    }
    
    // iPhone 14 (390px width)
    @media only screen and (device-width: 390px) and (device-height: 844px),
           only screen and (device-width: 428px) and (device-height: 926px) {
        .about__hero {
            min-height: 65vh;
            margin-top: -8rem;
            
            &__heading {
                font-size: 2.4rem;
                margin-bottom: 1.2rem;
            }
            
            &__paragraph {
                font-size: 1.3rem;
                line-height: 1.4;
                margin-bottom: 1.5rem;
            }
            
            &__button {
                min-width: 200px;
                width: 90%;
                max-width: 260px;
                padding: 1rem 2rem;
                font-size: 1.3rem;
                margin: 1rem auto;
            }
            
            &__stats {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap: 1.5rem;
                margin-top: 2rem;
                
                .stat {
                    flex: 0 0 auto;
                    width: calc(33% - 1rem);
                    min-width: 110px;
                    max-width: 130px;
                    padding: 0.8rem;
                    margin-bottom: 0;
                    
                    &__number {
                        font-size: 2rem;
                        margin-bottom: 0.3rem;
                    }
                    
                    &__label {
                        font-size: 1.1rem;
                    }
                }
            }
        }
    }

    // Surface Duo (540px width)
    @media only screen and (device-width: 540px) and (device-height: 720px) {
        .about__hero {
            min-height: 75vh;
            margin-top: -9rem;
            
            &__heading {
                font-size: 3.2rem;
                margin-bottom: 1.5rem;
            }
            
            &__paragraph {
                font-size: 1.6rem;
                line-height: 1.4;
                margin-bottom: 2rem;
            }
            
            &__button {
                min-width: 220px;
                width: 80%;
                max-width: 300px;
                font-size: 1.5rem;
            }
            
            &__stats {
                display: flex;
                flex-wrap: nowrap;
                justify-content: center;
                gap: 2rem;
                margin-top: 3rem;
                
                .stat {
                    flex: 1;
                    max-width: 160px;
                    padding: 1rem;
                    
                    &__number {
                        font-size: 2.4rem;
                    }
                    
                    &__label {
                        font-size: 1.2rem;
                    }
                }
            }
        }
    }

    // Fix horizontal alignment for stats on all small screens
    @media only screen and (max-width: 480px) {
        .about__hero {
            &__stats {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                gap: 1.5rem;
                margin-top: 2rem;
                
                .stat {
                    flex: 0 0 auto;
                    width: calc(33% - 1rem);
                    min-width: 100px;
                    max-width: 120px;
                    padding: 0.8rem;
                    margin-bottom: 0;
                    
                    &__number {
                        font-size: 2.2rem;
                        margin-bottom: 0.2rem;
                    }
                    
                    &__label {
                        font-size: 1.1rem;
                        letter-spacing: 0;
                    }
                }
            }
        }
    }

    // Override grid layout for stats in existing media queries
    @media only screen and (max-width: 428px) {
        .about__hero {
            &__stats {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                grid-template-columns: none;
                
                .stat {
                    width: calc(33% - 1rem);
                }
            }
        }
    }

    @media only screen and (max-width: 390px) {
        .about__hero {
            &__stats {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                gap: 1.2rem;
                
                .stat {
                    width: calc(33% - 1rem);
                    min-width: 90px;
                }
            }
        }
    }

    @media only screen and (max-width: 360px) {
        .about__hero {
            &__stats {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
                gap: 1rem;
                
                .stat {
                    width: calc(33% - 0.7rem);
                    min-width: 80px;
                    max-width: 100px;
                    padding: 0.6rem;
                }
            }
        }
    }
    
    