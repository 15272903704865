.date-picker {
    input[type="date"] {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 16px; // Better for mobile
        width: 100%;
        max-width: 200px;
        
        // Reset appearance
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        
        // Better touch target
        height: 40px;
        
        // Custom calendar icon
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: right 8px center;
        background-size: 20px;
        padding-right: 32px;
    }

    // Improve tap target size on mobile
    @media (max-width: 768px) {
        input[type="date"] {
            height: 44px;
            font-size: 16px;
        }
    }

    .selected-date {
        display: block;
        margin-top: 8px;
        font-size: 14px;
        color: #666;
    }

    .date-error {
        color: #dc3545;
        font-size: 14px;
        margin-top: 4px;
    }
} 