// Clearfix Mixin: Clears floats
@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

// Absolute Center Mixin: Centers an element absolutely
@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Responsive Mixin: Applies styles based on the specified breakpoint
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) { @content };        // 600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) { @content };       // 900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { @content };          // 1200px
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { @content };       // 1800px
    }
}

// Flexbox Center Mixin: Centers content using Flexbox
@mixin flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

// Grid Layout Mixin: Sets up a basic grid layout
@mixin gridLayout($columns, $gap) {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
    gap: $gap;
}
