.pagination {
    &__number {
        display: inline-block;
        font-size: 1.6rem;
        padding: 1rem;
        color: $color-primary;
        border: 0.1rem solid black;
        cursor: pointer;
        margin: 0.1rem;
        user-select: none;

        &--active {
            color: $color-white;
            background-color: hsl(124, 47%, 43%);;
        }

        &--disabled {
            opacity: 0.5;
            cursor: not-allowed;
            
            &:hover {
                color: $color-primary;
                background-color: transparent;
            }
        }

        &:hover {
            color: $color-white;
            background-color:hsl(124, 47%, 43%);;
        }
    }
}
