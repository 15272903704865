.navbar {
    width: 100%;
    padding: 0 4rem !important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: all 0.4s ease;
    height: 16rem;
    display: flex;
    align-items: center;
    
    // Base styling with transparent background (default for About page)
    background-color: transparent;
    
    // Page-specific styles
    &.navbar-listings,
    &.navbar-company {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: rgba(0, 0, 0, 0.9); // Solid background
        opacity: 1 !important; // Always visible
        height: 8rem; // Reduced height from 14rem to 8rem
        transition: none; // No transitions
        transform: none; // No transform effects
        
        // Ensure elements remain visible regardless of scroll position
        &.scrolled {
            background-color: rgba(0, 0, 0, 0.9);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
            height: 8rem; // Match the reduced height
        }
        
        // Adjust logo for these pages
        .navbar__logo__image {
            width: 100px; // Smaller logo specifically for these pages
        }
        
        @media only screen and (max-width: 768px) {
            height: 6.5rem; // Smaller on mobile
            
            &.scrolled {
                height: 6.5rem;
            }
            
            .navbar__logo__image {
                width: 75px;
            }
        }
        
        @media only screen and (max-width: 480px) {
            height: 6rem; // Even smaller on small mobile
            
            &.scrolled {
                height: 6rem;
            }
            
            .navbar__logo__image {
                width: 65px;
            }
        }
    }
    
    &.navbar-about {
        // Transparent styling already applied in base style
        
        &.scrolled {
            // Only apply scrolled styles to About page
            background-color: rgba(0, 0, 0, 0.9);
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
            height: 10rem;
        }
    }
    
    &__container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        position: relative;
    }
    
    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-left: -130px; // Offset the logo width to truly center the nav items
        
        @media only screen and (max-width: 1200px) {
            margin-left: -100px; // Smaller offset for smaller screens
        }
    }
    
    &__logo {
        display: flex;
        align-items: center;
        text-decoration: none;
        
        &__image {
            width: 130px;
            height: auto;
            display: block;
            transition: transform 0.3s ease;
            filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.3));
            
            &:hover {
                transform: scale(1.05);
            }
        }
    }
    
    &__menu {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        list-style: none;
        
        &__item {
            list-style: none;
            margin: 0 1.8rem; // Reduced from 3.5rem to fit more items
            
            @media only screen and (max-width: 1200px) {
                margin: 0 1.5rem; // Further reduce margin between items for larger screens
            }
            
            &__link {
                position: relative;
                font-size: 1.8rem;
                color: $color-white;
                text-decoration: none;
                font-weight: 600;
                letter-spacing: 1px;
                padding: 0.8rem 0;
                white-space: nowrap; // Prevent text from wrapping
                
                transition: all 0.3s ease;
                
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: $color-white;
                    transition: width 0.3s ease;
                }
                
                &:hover {
                    color: $color-white;
                    
                    &::after {
                        width: 100%;
                    }
                }
                
                &.active {
                    color: #4CAF50;
                    font-weight: 700;
                    
                    &::after {
                        width: 100%;
                        background-color: #4CAF50;
                        height: 2px;
                    }
                }
            }
        }
    }
    
    &__auth {
        display: flex;
        gap: 2rem;
        
        &__link {
            font-size: 1.6rem;
            color: $color-white;
            text-decoration: none;
            transition: color 0.3s ease;
            
            &:hover {
                color: rgba(255, 255, 255, 0.8);
            }
        }
    }
    
    // Mobile menu toggle button
    &__mobile-toggle {
        display: none;
        background: transparent;
        border: none;
        cursor: pointer;
        padding: 0.5rem;
        z-index: 101;
        transition: all 0.3s ease;
        color: #fff;
        font-size: 2.2rem;
        
        &:focus {
            outline: none;
        }
    }
    
    // Responsive styles
    @media only screen and (max-width: 992px) {
        height: 8rem; // Reduced from 10rem
        padding: 0 2rem !important;
        
        &__nav {
            margin-left: -60px; // Adjusted offset for smaller screens with more menu items
            justify-content: flex-end; // Align menu to the right on tablets
        }
        
        &.navbar-listings,
        &.navbar-company {
            height: 7rem; // Even smaller height on tablets
            
            &.scrolled {
                height: 7rem;
            }
            
            .navbar__logo__image {
                width: 90px;
            }
        }
        
        &__logo {
            &__image {
                width: 90px; // Reduced from 100px
                height: auto;
            }
        }
        
        &__menu {
            &__item {
                margin: 0 1.2rem; // Reduced margin to fit more items
                
                &__link {
                    font-size: 1.5rem; // Slightly smaller font size
                }
            }
        }
    }
    
    @media only screen and (max-width: 768px) {
        height: 7rem;
        padding: 0 1.5rem !important;
        
        &__container {
            justify-content: space-between;
        }
        
        &__nav {
            margin-left: 0; // Remove margin offset
            width: auto; // Allow the nav to take its natural width
            justify-content: flex-end; // Align to the right
            margin-right: 1rem; // Add some spacing from the right edge
        }
        
        &__menu {
            flex-direction: row;
            gap: 0.5rem; // Add gap instead of margins
            
            &__item {
                margin: 0 0.7rem; // Smaller margins
                
                &__link {
                    font-size: 1.2rem; // Reduce font size
                    padding: 0.5rem 0;
                }
            }
        }
        
        &.navbar-listings,
        &.navbar-company {
            height: 6.5rem; // Smaller on mobile
            
            &.scrolled {
                height: 6.5rem;
            }
            
            .navbar__logo__image {
                width: 75px;
            }
        }
    }
    
    @media only screen and (max-width: 576px) {
        &__menu {
            &__item {
                margin: 0 0.4rem; // Extremely small margins
                
                &__link {
                    font-size: 1rem; // Much smaller font
                    padding: 0.3rem 0;
                    letter-spacing: 0;
                }
            }
        }
    }
    
    @media only screen and (max-width: 480px) {
        height: 6.5rem;
        padding: 0 0.8rem !important;
        
        &__nav {
            margin-left: 0; // No offset
            padding-left: 0.4rem;
        }
        
        &__logo {
            &__image {
                width: 65px;
                height: auto;
            }
        }
        
        &__menu {
            padding: 0;
            
            &__item {
                margin: 0 0.3rem; // Minimum spacing
                
                &__link {
                    font-size: 0.9rem; // Even smaller font
                    letter-spacing: -0.2px; // Negative letter-spacing to save space
                }
            }
        }
        
        &.navbar-listings,
        &.navbar-company {
            height: 6rem; // Even smaller on small mobile
            
            &.scrolled {
                height: 6rem;
            }
            
            .navbar__logo__image {
                width: 60px;
            }
        }
    }
    
    // Keep standard styling for larger screens
    @media only screen and (min-width: 769px) {
        &__nav {
            display: flex;
            flex-direction: row;
            position: relative;
            width: 100%;
            height: auto;
            background: transparent;
            top: 0;
            right: 0;
            padding: 0;
            transition: none;
            box-shadow: none;
        }
        
        &__menu {
            flex-direction: row;
        }
    }
}

// Overlay for mobile menu
.navbar-overlay {
    display: none;
}