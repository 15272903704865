.listingdetail {
    padding-top: 8rem !important;
    padding-bottom: 4rem !important;
    max-width: 1300px !important;
    margin: 0 auto !important;

    @media only screen and (max-width: 992px) {
        padding-top: 7rem !important;
    }
    
    @media only screen and (max-width: 768px) {
        padding-top: 6.5rem !important;
        padding: 6.5rem 1rem 4rem !important;
    }
    
    @media only screen and (max-width: 480px) {
        padding-top: 6rem !important;
    }

    .listingdetail__content {
        display: flex !important;
        gap: 40px !important;
        margin: 3rem 0 !important;
        align-items: flex-start !important;

        @media only screen and (max-width: 992px) {
            gap: 30px !important;
            margin-left: 2rem !important;
            margin-right: 2rem !important;
        }
        
        @media only screen and (max-width: 768px) {
            flex-direction: column !important;
            margin-left: 0 !important;
            margin-right: 0 !important;
            gap: 20px !important;
        }
    }
    
    .listingdetail__media {
        flex: 2 !important;
        transition: all 0.3s ease !important;
        position: relative !important;
        max-width: 800px !important;

        @media only screen and (max-width: 768px) {
            overflow-x: auto !important;
            white-space: nowrap !important;
            padding-bottom: 1rem !important;
            max-width: 100% !important;
        }
    }
    
    .listingdetail__payment {
        flex: 1 !important;
        background-color: #f9f9f9 !important;
        padding: 2rem !important;
        border-radius: 10px !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
        max-width: 500px !important;

        @media only screen and (max-width: 992px) {
            max-width: 100% !important;
        }

        @media only screen and (max-width: 768px) {
            margin-top: 2rem !important;
            padding: 1.5rem !important;
        }

        h2 {
            color: hsl(124, 47%, 43%) !important;
            font-size: 1.8rem !important;
            font-weight: bold !important;
            margin-bottom: 1.5rem !important;
            text-align: center !important;
            padding-bottom: 0.5rem !important;
            border-bottom: 2px solid #e0e0e0 !important;

            @media only screen and (max-width: 480px) {
                font-size: 1.5rem !important;
            }
        }
    }

    // Date Picker
    .date-picker {
        margin-bottom: 1.5rem;

        label {
            font-weight: bold;
            display: block;
            margin-bottom: 0.5rem;
            font-size: 1.4rem;
        }

        input[type="date"] {
            padding: 0.8rem;
            border-radius: 6px;
            border: 1px solid #ddd;
            width: 100%;
            max-width: 220px;
            font-size: 1.2rem;
            transition: border-color 0.3s;

            &:focus {
                border-color: #2fa18a;
                outline: none;
                box-shadow: 0 0 0 2px rgba(47, 161, 138, 0.2);
            }

            @media only screen and (max-width: 480px) {
                max-width: 100%;
            }
        }

        span {
            display: block;
            margin-top: 0.5rem;
            color: #666;
            font-style: italic;
            font-size: 0.9rem;
        }
        
        .date-error {
            display: block;
            margin-top: 0.5rem;
            color: #d9534f;
            font-weight: bold;
            font-size: 1rem;
            padding: 0.5rem;
            background-color: rgba(217, 83, 79, 0.1);
            border-radius: 4px;
            border-left: 3px solid #d9534f;
        }
    }

    // Penalty Section
    .penalty {
        margin: 1.5rem 0;
        padding: 1rem;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #f8fff8;
        transition: background-color 0.3s;

        &.has-penalty {
            background-color: #fff8f8;
            border-color: #ffcccb;
        }

        label {
            font-weight: bold;
            font-size: 1rem;
        }

        input[readonly] {
            margin-left: 0.5rem;
            font-weight: bold;
            color: #5cb85c;
            border: none;
            background-color: transparent;
            width: 80px;
            font-size: 1rem;
            
            &.penalty-applied {
                color: #d9534f;
            }
        }

        p {
            font-size: 1rem;
            color: #5cb85c;
            margin-top: 0.5rem;
            font-style: italic;
            
            &.penalty-warning {
                color: #d9534f;
            }
        }
    }

    // Payment Form - Dropdowns
    .payment-form {
        margin-bottom: 1.5rem;

        label {
            display: block;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        select {
            width: 100%;
            padding: 0.8rem;
            border-radius: 6px;
            border: 1px solid #ddd;
            background-color: white;
            font-size: 1.2rem;
            margin-top: 0.3rem;
            transition: border-color 0.3s;
            
            &:focus {
                border-color: #2fa18a;
                outline: none;
                box-shadow: 0 0 0 2px rgba(47, 161, 138, 0.2);
            }
        }
    }

    // Property Selection
    .property {
        margin-bottom: 1.5rem;
        
        label {
            display: block;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        
        select {
            width: 100%;
            padding: 0.8rem;
            border-radius: 6px;
            border: 1px solid #ddd;
            background-color: white;
            font-size: 1.2rem;
            margin-top: 0.3rem;
            transition: border-color 0.3s;
            
            &:focus {
                border-color: #2fa18a;
                outline: none;
                box-shadow: 0 0 0 2px rgba(47, 161, 138, 0.2);
            }
        }
    }

    // Amount Input
    .amount {
        margin-bottom: 1.5rem;
        
        label {
            display: block;
            font-size: 1.2rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
        }
        
        input {
            width: 100%;
            padding: 0.8rem;
            border-radius: 6px;
            border: 1px solid #ddd;
            font-size: 1rem;
            transition: border-color 0.3s;
            
            &:focus {
                border-color: #2fa18a;
                outline: none;
                box-shadow: 0 0 0 2px rgba(47, 161, 138, 0.2);
            }
        }
    }

    // Total Amount Display
    .total-amount {
        margin: 1.5rem 0 2rem;
        padding: 1rem;
        background-color: #f5f5f5;
        border-radius: 8px;
        border: 1px solid #ddd;
        
        div {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
            font-size: 1.2rem;
            
            &:last-child {
                margin-top: 0.8rem;
                padding-top: 0.8rem;
                border-top: 1px dashed #ddd;
                font-weight: bold;
                font-size: 1.2rem;
            }
        }
        
        .penalty-amount {
            color: #d9534f;
        }
        
        .no-penalty {
            color: #5cb85c;
        }
    }

    // Pay with Stripe Button
    .payment-btn {
        display: block;
        width: 100%;
        padding: 1rem;
        margin: 1.5rem 0 0;
        font-size: 1.1rem;
        font-weight: bold;
        background-color: hsl(124, 47%, 43%);
        color: white;
        border: none;
        border-radius: 30px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        
        &:hover {
            background-color: darken(#2fa18a, 10%);
            transform: translateY(-2px);
            box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
        }
        
        &:active {
            transform: translateY(1px);
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        @media only screen and (max-width: 768px) {
            padding: 0.9rem;
            font-size: 1rem;
        }
    }
    
    // Error message for reservation status
    .reserved-message {
        color: #d9534f;
        font-weight: bold;
        font-size: 1.1rem;
        margin: 1.5rem 0;
        padding: 1rem;
        background-color: rgba(217, 83, 79, 0.1);
        border-radius: 8px;
        text-align: center;
    }

    // View More Listings button
    .view-more-btn {
        display: inline-block;
        margin: 1rem auto;
        padding: 0.8rem 1.5rem;
        background-color: #28a745;
        color: white;
        border-radius: 30px;
        text-decoration: none;
        font-weight: bold;
        font-size: 1rem;
        transition: all 0.3s ease;
        text-align: center;
        
        &:hover {
            background-color: darken(#28a745, 10%);
            transform: translateY(-2px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        }
    }

    &__header {
        background-color: rgba($color-primary, 0.45);
        padding-top: 4rem;
        padding-bottom: 4rem;
        text-align: center;
        border-radius: 8px;
        margin-bottom: 2rem;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    }

    &__title {
        font-size: 4.2rem;
        color: $color-white;
        margin-bottom: 2rem;
        font-weight: 200;
    }

    &__location {
        font-size: 2.4rem;
        color: $color-white;
        font-weight: 200;
    }

    &__breadcrumb {
        background-color: $color-grey-light !important;
        padding: 1.5rem 3rem !important;
        font-size: 1.8rem !important;
        margin: 1rem 0 2rem !important;
        border-radius: 8px !important;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05) !important;

        @media only screen and (max-width: 768px) {
            padding: 1rem 2rem !important;
            font-size: 1.6rem !important;
        }
        
        &__link {
            color: $color-primary !important;
            text-decoration: none !important;
            font-weight: 500 !important;
            
            &:hover {
                color: $color-tertiary !important;
                text-decoration: underline !important;
            }
        }
    }

    &__display {
        width: 100% !important;
        text-align: center !important;
        border-radius: 12px !important;
        overflow: hidden !important;
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1) !important;
        margin-bottom: 1.5rem !important;
        position: relative !important;

        @media only screen and (max-width: 768px) {
            width: auto !important;
            display: inline-block !important;
            margin-right: 1rem !important;
            margin-bottom: 1rem !important;
        }

        &__image {
            width: 100% !important;
            max-height: 600px !important;
            object-fit: cover !important;
            border-radius: 10px !important;
            transition: transform 0.4s ease !important;

            &:hover {
                transform: scale(1.02) !important;
            }

            @media only screen and (max-width: 768px) {
                width: auto !important;
                height: 200px !important;
            }
        }
    }

    .listingdetail__thumbnails__thumbnail__video {
        width: 100%;
        height: 75px;
        cursor: pointer;
        border-radius: 0.5rem;
        transition: transform 0.2s;
        
        &:hover {
            transform: scale(1.05);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
        }
    }
    
    .listingdetail__display__video {
        width: 100%;
        border-radius: 1rem;
    }
    
    &__thumbnails {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
        gap: 10px;
        margin-top: 1.5rem;
        
        @media only screen and (max-width: 768px) {
            display: flex;
            justify-content: flex-start;
            overflow-x: auto;
            gap: 8px;
        }

        &__thumbnail {
            position: relative;
            overflow: hidden;
            border-radius: 6px;
            transition: all 0.2s ease;
            
            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
            }

            &__image {
                width: 100%;
                height: 75px;
                object-fit: cover;
                cursor: pointer;
                border-radius: 5px;
                transition: transform 0.3s;

                @media only screen and (max-width: 768px) {
                    width: 100px;
                    height: 60px;
                }

                &:hover {
                    transform: scale(1.1);
                    border: 2px solid $color-primary;
                }
            }
        }
    }

    .row {
        margin-top: 3rem;
        padding: 0 1rem;
        
        @media only screen and (max-width: 768px) {
            margin-top: 2rem;
        }
    }

    &__realtor {
        font-size: 2.4rem;
        color: $color-primary;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    &__contact {
        font-size: 1.6rem;
        color: $color-tertiary;
    }

    &__about {
        font-size: 1.6rem;
        margin-top: 1rem;
    }

    &__list {
        list-style: none;
        font-size: 1.7rem;
        background-color: #f9f9f9;
        color: $color-black;
        border-radius: 10px;
        padding: 2.5rem;
        margin-bottom: 0;
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.05);
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        border-left: 5px solid rgba(124, 177, 134, 0.5);
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        
        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
        }

        @media only screen and (max-width: 768px) {
            font-size: 1.5rem;
            padding: 2rem;
        }

        &__item {
            margin-bottom: 1.2rem;
            padding-left: 0.5rem;
            display: flex;
            align-items: flex-start;
            line-height: 1.4;
            
            &:last-child {
                margin-bottom: 0;
            }
            
            /* Style for the checkmark emoji */
            &:before {
                content: "✅";
                margin-right: 10px;
                font-size: 1.6rem;
            }
        }
    }

    .col-1-of-2 {
        @media only screen and (min-width: 769px) {
            padding: 0 1rem;
        }
    }

    .property-heading {
        font-size: 2.6rem !important;
        font-weight: 600 !important;
        color: hsl(124, 47%, 43%) !important;
        margin-bottom: 2rem !important;
        padding-bottom: 1rem !important;
        position: relative !important;
        
        &:after {
            content: '' !important;
            position: absolute !important;
            bottom: 0 !important;
            left: 0 !important;
            width: 80px !important;
            height: 3px !important;
            background-color: hsl(124, 47%, 43%) !important;
            border-radius: 1.5px !important;
        }
        
        @media only screen and (max-width: 768px) {
            font-size: 2.2rem !important;
            margin-bottom: 1.5rem !important;
            padding-bottom: 0.8rem !important;
        }
    }
    
    .property-info-container {
        display: flex;
        gap: 2rem;
        margin-bottom: 3rem;
        
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            gap: 1.5rem;
        }
    }

    .col-1-of-2 {
        flex: 1;
        
        @media only screen and (min-width: 769px) {
            padding: 0 1rem;
        }
    }

    .description-container {
        margin-bottom: 3.5rem;
        position: relative;
        background-color: #f9f9f9;
        border-radius: 12px;
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.05);
        overflow: hidden;
        
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 5px;
            background-color: hsl(124, 47%, 43%);
            opacity: 0.7;
        }
        
        @media only screen and (max-width: 768px) {
            margin-bottom: 2.5rem;
        }
    }

    &__description {
        font-size: 1.8rem;
        color: $color-black;
        border-radius: 0 10px 10px 0;
        padding: 3rem;
        line-height: 1.9;
        background-color: transparent;
        box-shadow: none;
        max-width: 100%;
        column-count: 1;
        column-gap: 3.5rem;
        letter-spacing: 0.03rem;
        position: relative;
        
        /* First letter styling */
        &::first-letter {
            font-size: 3.2rem;
            font-weight: 600;
            color: hsl(124, 47%, 43%);
            float: left;
            line-height: 1;
            padding-right: 0.8rem;
            margin-top: 0.3rem;
        }
        
        /* Add proper paragraph spacing for long text */
        p + p {
            margin-top: 2rem;
        }
        
        /* Split into columns on large screens for better readability */
        @media only screen and (min-width: 992px) and (min-height: 800px) {
            column-count: 2;
            
            /* Prevent orphaned words at column breaks */
            word-break: break-word;
            hyphens: auto;
            
            /* Add a subtle column separator */
            column-rule: 1px solid rgba(124, 177, 134, 0.3);
            padding-left: 3.5rem;
            padding-right: 3.5rem;
        }
        
        /* Responsive adjustments */
        @media only screen and (max-width: 768px) {
            font-size: 1.6rem;
            padding: 2rem;
            line-height: 1.7;
            margin-bottom: 0;
            
            &::first-letter {
                font-size: 2.6rem;
                padding-right: 0.6rem;
            }
        }
    }

    // SOLD indicator style
    .sold-indicator {
        position: absolute !important;
        top: 20px !important;
        right: 20px !important;
        width: 150px !important;
        height: 150px !important;
        background-color: red !important;
        color: white !important;
        font-size: 40px !important;
        font-weight: bold !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        border-radius: 50% !important;
        z-index: 10 !important;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;

        @media only screen and (max-width: 768px) {
            width: 100px !important;
            height: 100px !important;
            font-size: 26px !important;
            top: 10px !important;
            right: 10px !important;
        }

        @media only screen and (max-width: 480px) {
            width: 80px !important;
            height: 80px !important;
            font-size: 20px !important;
        }
    }
}