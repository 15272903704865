// Protective Covenants Page Styling
.protective-covenants-page {
  padding-top: 14rem; // Match the padding from other pages for fixed navbar
  max-width: 1300px;
  margin: 0 auto;
  padding-bottom: 4rem;
  overflow-x: hidden;

  @media only screen and (max-width: 992px) {
    padding-top: 10rem;
  }
  
  @media only screen and (max-width: 768px) {
    padding-top: 8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

// Hero Section
.protective-hero {
  position: relative;
  background: linear-gradient(135deg, hsl(124, 47%, 43%) 0%, hsl(124, 47%, 25%) 100%);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 3rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");
    opacity: 0.6;
  }

  .protective-hero-overlay {
    padding: 5rem 3rem;
    text-align: center;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 768px) {
      padding: 4rem 1.5rem;
    }

    h1 {
      font-size: 4rem;
      color: #fff;
      margin-bottom: 1.5rem;
      font-weight: 700;
      text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

      @media only screen and (max-width: 768px) {
        font-size: 3rem;
      }

      @media only screen and (max-width: 480px) {
        font-size: 2.5rem;
      }
    }

    p {
      font-size: 1.8rem;
      color: rgba(255, 255, 255, 0.9);
      max-width: 800px;
      margin: 0 auto;
      line-height: 1.6;

      @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }
}

// Main Content
.covenants-content {
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  padding: 4rem;
  position: relative;

  @media only screen and (max-width: 768px) {
    padding: 2.5rem;
  }

  @media only screen and (max-width: 480px) {
    padding: 1.5rem;
  }
}

// Introduction
.covenants-intro {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }

  .covenant-shield-icon {
    font-size: 3.5rem;
    color: hsl(124, 47%, 43%);
    margin-right: 2rem;
    padding: 1rem;
    background-color: rgba(124, 177, 134, 0.1);
    border-radius: 50%;
    flex-shrink: 0;

    @media only screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 1.5rem;
      font-size: 3rem;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 1.7;
    color: #333;

    @media only screen and (max-width: 768px) {
      font-size: 1.6rem;
    }
  }
}

// Covenants List
.covenants-list {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.covenant-item {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  }

  .covenant-number {
    background-color: #000;
    color: #fff;
    font-size: 2rem;
    font-weight: 700;
    min-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;

    @media only screen and (max-width: 768px) {
      min-width: 60px;
      font-size: 1.8rem;
    }

    @media only screen and (max-width: 480px) {
      min-width: 50px;
      font-size: 1.6rem;
      padding: 1rem;
    }
  }

  .covenant-text {
    flex: 1;
    padding: 1.5rem 2rem;
    border-left: 4px solid hsl(124, 47%, 43%);

    @media only screen and (max-width: 480px) {
      padding: 1rem 1.5rem;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.7;
      color: #333;
      margin: 0;

      @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
      }

      @media only screen and (max-width: 480px) {
        font-size: 1.4rem;
        line-height: 1.6;
      }
    }
  }
}

// Footer
.covenants-footer {
  margin-top: 4rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: flex-start;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .covenant-check-icon {
    font-size: 2.5rem;
    color: hsl(124, 47%, 43%);
    margin-right: 1.5rem;
    margin-top: 0.5rem;
    flex-shrink: 0;

    @media only screen and (max-width: 768px) {
      margin-right: 0;
      margin-bottom: 1.5rem;
    }
  }

  .covenant-footer-text {
    @media only screen and (max-width: 768px) {
      text-align: center;
    }

    p {
      font-size: 1.6rem;
      line-height: 1.7;
      color: #555;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
        font-weight: 500;
      }

      @media only screen and (max-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }
} 