.listings {
    &__form {
        margin-top: 4rem;
    }

    &__listings {
        margin-top: 6rem;
        margin-bottom: 4rem;
        width: 100%;
        padding: 0 2rem;
    }

    &__pagination {
        margin-bottom: 6rem;
        margin-left: 0;
        display: flex;
        justify-content: center;
    }

    padding-top: 8rem;
    
    @media only screen and (max-width: 992px) {
        padding-top: 7rem;
    }
    
    @media only screen and (max-width: 768px) {
        padding-top: 6.5rem;
    }
    
    @media only screen and (max-width: 480px) {
        padding-top: 6rem;
    }
}