.card {
    
        padding: 1rem 1.5rem;
        background-color: $color-grey-light;
        border-radius: 0.5rem;
        width: 100%;
        max-height: 100%;
        margin: 0.5rem 0 1.5rem 0; /* More compact margins */
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    
    
    
    

    &__header {
        width: 100%;

        &__photo {
            width: 100%;
            height: auto;
            display: block;
            border-radius: 0.3rem;
        }
    }

    &__title {
        color: $color-primary;
        font-size: 2.4rem;
        margin-bottom: 2rem;
    }

    &__location {
        font-size: 1.4rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    &__info {
        font-size: 1.6rem;
        color: $color-tertiary;
        margin-bottom: 0.5rem;
    }

    &__saletype {
        font-size: 1.6rem;
        color: $color-green;
        margin-bottom: 0.5rem;
    }

    &__hometype {
        font-size: 1.6rem;
        color: $color-tertiary;
        margin-bottom: 0.5rem;
    }

    &__sqft {
        font-size: 1.4rem;
    }

    &__link {
        font-size: 1.6rem;
        color: black;
        padding: 1rem 1.5rem;
        border: 0.1rem solid black;
        text-decoration: none;

        &:hover {
            color: $color-white;
            border: 0.1rem solid hsl(124, 47%, 43%);
            background-color: hsl(124, 47%, 43%);
        }
    }
}
