.company-page {
  overflow-x: hidden;
  font-family: 'Bebas Neue', sans-serif;
  color: #333;
  max-width: 100%;
  padding-top: 8rem; // Navbar spacing
  
  @media only screen and (max-width: 992px) {
    padding-top: 7rem; // Reduced for tablets
  }
  
  @media only screen and (max-width: 768px) {
    padding-top: 6.5rem; // Reduced for mobile
  }
  
  @media only screen and (max-width: 480px) {
    padding-top: 6rem; // Reduced for small mobile
  }
}

/* Hero Section */
.company-hero {
  width: 100%;
  padding: 6rem 0;
  background-color: #f8f9fa;
  overflow: hidden;
  margin-bottom: 5rem;
}

.company-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem;
  
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    text-align: center;
    padding: 0 2rem;
    padding-top: 7rem;
  }
}

.company-hero-text {
  flex: 1;
  padding-left: 5rem;
  
  @media only screen and (max-width: 992px) {
    padding-left: 0;
    padding-top: 3rem;
  }
}

.company-hero-image {
  width: 50%;
  border-radius: 20px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.15);
  object-fit: cover;
  
  @media only screen and (max-width: 992px) {
    width: 90%;
    margin: 0 auto;
  }
}

.company-hero-title {
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 1.6;
  color: #333;
  font-style: italic;
  margin-bottom: 2rem;
  position: relative;
  text-align: left;
  
  &:before {
    content: '"';
    font-size: 6rem;
    color: rgba(26, 164, 46, 0.15);
    position: absolute;
    top: -2rem;
    left: -2rem;
    font-family: serif;
  }
  
  @media only screen and (max-width: 768px) {
    font-size: 2.2rem;
    text-align: center;
    
    &:before {
      font-size: 4rem;
      top: -1.5rem;
      left: -1rem;
    }
  }
}

.company-hero-attribution {
  font-size: 1.8rem;
  font-style: italic;
  color: #1aa42e;
  text-align: left;
  margin-top: 1rem;
  font-weight: 600;
  
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
  
  @media only screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.company-logo {
  background-color: #009688;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  
  h3 {
    color: #333;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding: 0.5rem;
  }
}

.hero-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: white;
  color: #333;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.4rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
}

/* Feature Columns */
.feature-columns {
  display: flex;
  justify-content: space-between;
  padding: 4rem 8%;
  margin-bottom: 6rem;
  flex-wrap: wrap;
  gap: 2.5rem;
}

.feature-column {
  flex: 1;
  padding: 2rem;
  min-width: 320px;
  max-width: 420px;
  margin: 0 auto;
  
  h3 {
    font-size: 2.2rem;
    font-weight: 600;
    margin: 2rem 0 1.5rem;
    color: hsl(124, 47%, 43%);
  }
  
  p {
    font-size: 1.6rem;
    line-height: 1.8;
    color: #555;
    text-align: justify;
  }
}

.feature-image {
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* About Section */
.company-about {
  background-color: #f9f9f9;
  padding: 1rem -2%;
  margin-bottom: -3rem;
  text-align: right;
}

.about-content {
  max-width: 78%;
  margin: 0 auto;
 
  
  h2 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-top: 6rem;
    color:  hsl(124, 47%, 43%);
    text-align: center;
  }
  
  p {
    font-size: 1.4rem;
    line-height: 2.0;
    margin-bottom: 2rem;
    color: #555;
    justify-content: left;
    width: 100%;
    text-align: justify;
  }
}

.company-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  
  .feature-icon {
    color: #00bfa5;
    margin-right: 1rem;
    font-size: 1.6rem;
  }
  
  span {
    font-size: 1.6rem;
    font-weight: 500;
    
  }
}

.cta-button {
  display: inline-block;
  padding: 1rem 2.2rem;
  background-color: #00bfa5;
  color: white;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.6rem;
  transition: all 0.3s ease;
  margin-right: 45rem;
  &:hover {
    background-color: darken(#00bfa5, 5%);
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

/* Testimonials Section */
.testimonials-section {
  padding: 6rem 8%;
  margin-bottom: 5rem;
  text-align: center;
  
  h2 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 4rem;
    color:  hsl(124, 47%, 43%);
    
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 3px;
      background-color:#1aa42e97;
    }
  }
}

.testimonials-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3rem;
}

.testimonial-card {
  flex: 1;
  min-width: 320px;
  background-color: white;
  padding: 3rem;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
  text-align: left;
  margin-bottom: 1rem;
  
  .testimonial-text {
    font-size: 1.8rem;
    line-height: 1.8;
    color: #444;
    font-style: italic;
    margin-bottom: 2rem;
  }
  
  .testimonial-author {
    font-size: 1.6rem;
    font-weight: 600;
    color: #1aa42e97;
    margin-top: 1rem;
  }
}

/* CTA Section */
.cta-section {
  background-color: #2c713f;
  padding: 4rem 10%;
  margin-bottom: 4rem;
  text-align: center;
  color: white;
}

.cta-content {
  max-width: 800px;
  margin: 0 auto;
  
  h2 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  
  }
}

.cta-button-large {
  display: inline-block;
  padding: 1.2rem 3rem;
  background-color: #1aa42e97;
  color: white;
  border-radius: 30px;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.8rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
}

/* Responsive Styles */
@media only screen and (max-width: 992px) {
  .feature-columns {
    flex-direction: column;
    align-items: center;
  }
  
  .feature-column {
    margin-bottom: 2rem;
    max-width: 100%;
  }
  
  .testimonials-container {
    flex-direction: column;
    align-items: center;
  }
  
  .testimonial-card {
    margin-bottom: 2rem;
    max-width: 100%;
  }
  
  .company-hero {
    padding: 4rem 0;
  }

  .company-hero-image {
    margin-top: 2rem;
  }
}

@media only screen and (max-width: 768px) {
  .company-hero {
    padding: 3rem 0;
  }
  
  .company-hero-content {
    padding: 0 1.5rem;
  }
  
  .company-logo {
    width: 100px;
    height: 100px;
  }
  
  .feature-columns,
  .company-about,
  .testimonials-section,
  .cta-section {
    padding: 3rem 5%;
  }
  
  .about-content {
    max-width: 90%;
    text-align: center;
  }
  
  .about-content h2,
  .testimonials-section h2,
  .cta-content h2 {
    font-size: 2.8rem;
  }
  
  .feature-column h3 {
    font-size: 1.8rem;
  }
  
  .feature-column p,
  .about-content p,
  .testimonial-card .testimonial-text {
    font-size: 1.4rem;
  }
  
  .company-hero-image {
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 480px) {
  .company-hero {
    padding: 2rem 0;
  }
  
  .company-hero-title {
    font-size: 1.8rem;
    
    &:before {
      font-size: 3rem;
      top: -1rem;
      left: -0.5rem;
    }
  }
  
  .company-hero-attribution {
    font-size: 1.3rem;
  }
  
  .company-hero-image {
    width: 100%;
  }
  
  .hero-button,
  .cta-button,
  .cta-button-large {
    padding: 0.8rem 1.8rem;
    font-size: 1.4rem;
  }
  
  .company-logo {
    width: 80px;
    height: 80px;
  }
  
  .feature-image {
    height: 180px;
  }
  
  .about-content {
    max-width: 100%;
  }
  
  .about-content h2 {
    font-size: 2.5rem;
    margin-top: 3rem;
  }
  
  .about-content p {
    font-size: 1.2rem;
    line-height: 1.8;
  }
  
  .testimonial-card {
    padding: 1.5rem;
  }
  
  .testimonial-card .testimonial-text {
    font-size: 1.2rem;
  }
  
  .testimonial-author {
    font-size: 1.2rem;
  }
  
  .cta-content h2 {
    font-size: 2.2rem;
  }
  
  .company-hero-image {
    width: 95%;
    height: auto;
  }
}

/* Additional Mobile Responsiveness */
@media only screen and (max-width: 360px) {
  .company-hero {
    height: auto;
  }
  
  .company-hero-title {
    font-size: 1.8rem;
    margin-bottom: 0;
  }
  
  .feature-column {
    padding: 1rem;
  }
  
  .feature-column h3 {
    font-size: 1.6rem;
  }
  
  .feature-column p {
    font-size: 1.1rem;
  }
  
  .about-content h2 {
    font-size: 2.2rem;
  }
  
  .cta-button-large {
    padding: 0.7rem 1.5rem;
    font-size: 1.3rem;
  }
}